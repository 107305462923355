@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;700&family=IBM+Plex+Sans+Arabic:wght@500&family=Roboto:ital@0;1&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    box-sizing: inherit;
    overflow-x: hidden !important;
    /* direction: ltr; */
    font-family: Tajawal-Regular, sans-serif;
    /* background-color: #f4f5fc !important; */
}

.colo-header {
    color: white;
    text-decoration: none;
}

.colo-header:hover {
    color: wheat;
}

.main-header {
    background-color: white;
    color: white;
    padding: 10px;
}
.Bestoffers{
    border-bottom: 3px solid #181b3c !important;
}
.inputborder{
    border: 2px solid #181b3c !important;
}
.responsive-header {
    background-color: rgb(0, 28, 70);
    color: white;
    padding: 10px;
    border-radius: 0px 0px 20px 20px;
}

.responsive-nav {
    display: none;

}

.logo {
    width: 100px;
}

.text-decoration {
    text-decoration: none;
    color: white;
}

.text-decoration:hover {
    color: white;
}

.list-style-ul {
    list-style: none;
}

.list-style-ul li a {
    text-decoration: none;
    color: black;
}

.list-style-ul li {
    padding: 7px 0px;
}

.list-style-ul li:hover {
    background-color: rgb(236, 235, 235);

}

.nav-list li {
    padding: 13px;

}

.nav-link {
    color: blue;
}

@media only screen and (max-width: 600px) {
    .main-header {
        display: none;
    }
}

.taksit img {
    border-radius: 30px;
}

.img-raduis {
    border-radius: 30px;
}

.section-parent {
    background-color: #cde3efe9;
    border-radius: 30px;
    position: relative;

    color: black;
}

.child-img {
    position: absolute;
    top: -32px;
}

.child-img img {
    width: 50px;
    height: 50px;
}

.discount-img {
    position: relative;
    transition: 0.5s;
    /* height: 20vh; */

}
.discount-img:hover{
    transform: scale(1.1, 1.1)
}
.discount-img img {
    width: 100%;
}
.slick-track {
    margin-bottom: 30px;
}
.discount-text {
    position: absolute;
    top: 40%;
    right: 30px;
    font-size: 44px;
    font-weight: bold;
}

.discount-position {
    position: absolute;
    right: 0;
}

.discount-two-text {
    position: absolute;
    top: 30%;
    color: white;
    font-size: 44px;
    font-weight: bold;
    padding: 20px;
}

.discount-three-text {
    position: absolute;
    top: 40%;
    right: 60px;
    font-size: 44px;
    font-weight: bold;
}

/* @media (min-width: 992px) */
.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 0.5rem;
}

/* 33333333333333333333333333333333 */
.search-header svg {
    color: white;

}

.search-header {
    width: 55px !important;
    height: 39px !important;
    background-color: #181b3c !important;
    border-radius: 0 15px 15px 0;
    /* border-radius: 10px;  */

}

.login select,
.login i {
    background-color: transparent !important;
    color: white !important;
}

.bg-min-color {
    background-color: #1e2d7d;
}
.min-color{
    color: #1e2d7d !important;
}

.w-55 {
    width: 55px;
}

.h-1 {
    height: 1px;
    background-color: wheat;
}

.text-color {
    color: #404553;
}

.icone-hoder {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #a9a9a9;
    cursor: pointer;
}

.d-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hover-icone-header:hover .icone-hoder {
    background-color: #006bb4;
    color: white;
    transition: 0.5s;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0rem !important;
    padding-right: 0.5rem !important;
}

.header-img {
   min-height: 50vh;
    background-size: cover cover;
    background-repeat: no-repeat;

}
.header-img-show{
    height: 50vh;
    background-size: cover cover;
    background-repeat: no-repeat;
}
.img-header:hover{
    transform: scale(1.1, 1.1)
}
.img-header{
    transition: 0.5s;
}
.ltr-section{
    direction: rtl !important;
}


li a{
    color: black !important;
}



/* Start Slider */


      

.post-slide {
    background: #fff;
    margin: 20px 15px 20px;
    border-radius: 15px;
    padding-top: 1px;
    /* box-shadow: 0px 14px 22p x -9px #bbcbd8; */
}
.post-slide .over-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: linear-gradient(-45deg, rgba(6, 190, 244, 0.75) 0%, rgba(45, 112, 253, 0.6) 100%);
    transition: all 0.50s linear;
}

.post-slide:hover .over-layer {
    opacity: 1;
    text-decoration: none;
}

.post-slide .over-layer i {
    position: relative;
    top: 45%;
    text-align: center;
    display: block;
    color: #fff;
    font-size: 25px;
}

.post-slide .post-content {
    background: #fff;
    padding: 2px 20px 40px;
    border-radius: 15px;
}

.post-slide .post-title a {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    display: inline-block;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
}

.post-slide .post-title a:hover {
    text-decoration: none;
    color: #3498db;
}

.post-slide .post-description {
    line-height: 24px;
    color: #808080;
    margin-bottom: 25px;
}

.post-slide .post-date {
    color: #a9a9a9;
    font-size: 14px;
}

.post-slide .post-date i {
    font-size: 20px;
    margin-right: 8px;
    color: #CFDACE;
}

.post-slide .read-more {
    padding: 7px 20px;
    float: right;
    font-size: 12px;
    background: #2196F3;
    color: #ffffff;
    box-shadow: 0px 10px 20px -10px #1376c5;
    border-radius: 25px;
    text-transform: uppercase;
}

.post-slide .read-more:hover {
    background: #3498db;
    text-decoration: none;
    color: #fff;
}

.owl-controls .owl-buttons {
    text-align: center;
    margin-top: 20px;
}

.owl-controls .owl-buttons .owl-prev {
    background: #abb1b4;
    position: absolute;
    bottom: 45%;
    left: 15px;
    /* padding: 0 18px 0 15px; */
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border-radius: 50%;
    box-shadow: 3px 14px 25px -10px #92b4d0;
    transition: background 0.5s ease 0s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.owl-controls .owl-buttons .owl-prev:hover{
    background: #404242;

}
.owl-controls .owl-buttons .owl-next:hover{
    background: #404242;

}
.owl-controls .owl-buttons .owl-next {
    background: #abb1b4;
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    bottom: 45%;
    right: 15px;
    /* padding: 0 18px 0 18px; */
    border-radius: 50%;
    box-shadow: -3px 14px 25px -10px #92b4d0;
    transition: background 0.5s ease 0s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.owl-controls .owl-buttons .owl-prev:after,
.owl-controls .owl-buttons .owl-next:after {
    content: "<";
    font-family: FontAwesome;
    color: white;
    font-size: 30px;
}

.owl-controls .owl-buttons .owl-next:after {
    content: ">";
}

@media only screen and (max-width:1280px) {
    .post-slide .post-content {
        padding: 0px 15px 25px 15px;
    }
}
/* End Slider */
.direction{
    direction: ltr;
}
.footer-ul li a {
    text-decoration: none;
    color: #404553;
}
.footer-btn {
    background-color: #007aff;
}
.footer-btn:hover{
    background-color: #007aff !important;

}
.bg-bluo{
    background-color: #00599e;
    color: white;
}
.title-product {
    left: 62px;
    top: 25px;
}
.text-50{
    color: #c3b4b4;
}
.text-none{
    text-decoration: none;
}
.fa-star{
    color: yellow;
}
.font-13{
    font-size: 12px;
}
.over-product span{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
}
.over-produc span{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
}
.over-product{
    justify-content: start !important;
}
.text-green{
    color: green;
}
.cursor-pointer{
    cursor: pointer;
}
.img-product{
    padding: 20px !important;
}
.top-slider{
    background-color: #f4f5fc;
    border-radius: 20px 20px 0 0;

}
@media only screen and (max-width: 600px) {
    .header-img {
        min-height: 35vh;
    }
    .img-header{
        margin-top: 50px;
    }
    .header-img-show{
        height: 25vh;
    }
  }
  a{
    text-decoration: none;
  }
  .icone-footer{
    background-color: #808080 !important;
    width: 35px;
    height: 35px !important ;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }
  .footer-img{
    width: 100px;
  }
  
  .border-solid{
    border: 1px solid #808080;
  }
.btn-add-card{
    background-color: #00599e !important;
    color: white !important;
    font-weight: bold !important;
    margin: 9px !important;
    padding: 12px !important;
}
.btn-add-card:hover{
    background-color: #00599e;
    color: white;
}
.icone-shop{
    font-size: 30px;
}
.accordion-button::after {
    margin-left: 1px !important;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1rem !important;}
    .post-slideee {
        background: #fff;
        margin: 20px 15px 20px;
        border-radius: 15px;
        padding-top: 1px;
        
    }
.card-img-scal:hover{
    transform: scale(1.1, 1.1);
    cursor: pointer;
}
.card-img-scal{
    transition: 1s;
}
.counter-shop{
    background-color: black;
    color: white;
    padding: 0px 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center ;
    font-size: 17px;
    left: -3px !important;
    top: -9px;
}
.font-30{
    font-size: 30px;
}
/* .product-img img{
    border: 15px solid white;
} */
.form__field {
    display: block;
    padding: 12px;
    border-radius: 2px;
    border: 1px solid var(--form-border-color);
    width: 100%;
    line-height: normal;
    height: 48px;
    color: var(--heading-color);
    background: var(--input-background);
    /* -webkit-appearance: none; */
    border: 1px solid #808080;
    resize: none;
    font-size: 1rem;
    font-size: max(1rem,16px);
    box-shadow: 0 1px rgba(var(--border-color-rgb),.25) inset;
    transition: border-color .2s ease-in-out,box-shadow .2s ease-in-out,color .2s ease-in-out;
}
.border-radius{
    border-radius: 20px !important;
}
.aLogin:hover{
    text-decoration: underline;
}
.w-40{
    width: 40%;
}
.p-8{
    padding: 8px !important;
}
.background-facebook{
    background-color: #3b5998;
}
.header-posiion{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1211255;
    right: 0;

}
.w-120{
    width: 300px;
}
.h-35{
    height: 35px;
}
.bg-color{
    background-color: #f7f7f7;
}
.w-110{
    width: 120px;
}
.vh-100{
    height: 100vh;
}
.h-60{
    height: 60px;
}
p a{
    text-decoration: none;
}
.bg-transparnt{
    background-color: rgb(0 92 151 / 22%);
    border: 1px solid #1376c5;
    border-radius: 5px;

}
.div-Stycy{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    /* padding: 50px; */
    /* font-size: 20px; */
}
.delet-icone{
    color: red;
}
.owl-item{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}
li a {
    font-size: 13px !important;
    text-decoration: none;
}
.dropdown-menu ul {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
  }
  
  /* Show sub-level Dropdowns on hover */
  .dropdown-menu li:hover > ul {
    display: block;
  }
  
.font-16{
    font-size: 15px !important;
}
.font-14{
    font-size: 14px !important;
}
p a{
    font-size: 15px;
}

.h-45{
    height: 39px;
    border-radius: 15px 0 0 15px;
}
.font-12{
    font-size: 12px;
}
.h-56{
    height: 20vh;
}






/* تخصيص الصورة الأصلية */
.image-container {
position: relative;

overflow: hidden;
}

.image-container img {
width: 100%;
height: 100%;
object-fit: cover; /* لتغطية الصورة بالكامل داخل الحاوية */
transition: opacity 0.5s ease; /* تحويل الانتقال مع تأثير التدرج في الشفافية */
}

/* تحديد الصورة البديلة لل hover */
.image-container:hover img {
opacity: 0; /* جعل الصورة الأصلية غير مرئية */
}

.image-container:hover::before {
content: ""; /* إضافة عنصر تكوين لعرض الصورة البديلة */
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
/* background: url("../img/slider-card-1.jpg") center/cover no-repeat; تكوين الصورة البديلة */
border: 15px solid white;
opacity: 1; /* جعل الصورة البديلة مرئية */
transition: opacity 0.5s ease; /* تحويل الانتقال مع تأثير التدرج في الشفافية */
}

.overProductSlider{
  left: 0;
  top: 0;
}
.overProductSlider{
  padding: 3px 5px;
  font-size: 12px;
}

.font-20{
    font-size: 20px;
}
.Sold-out{
    background-color: #404242 !important;
    /* padding: 5px 10px !important; */
}

.font-17{
    font-size: 18px !important;
}
.d-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-danger{
    background-color: #dd4b39 !important;
    
}
.slick-list {
    margin-top: 35px;
}
.slider-cartCatogry{
    height: 200px !important;
}
 /* .post-slide{
    min-height: 420px;
}  */
.slick-dots{
    margin-top: 20px;
}
.top-slider{
    margin-top: 30px;
}

.slick-arrow{
    /* background-color: red !important; */
    color: white !important;
}
.slick-prev{
    right: 50px !important;
    /* background-color: red !important; */
    width: 30px !important;
    z-index: 22;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slick-prev {
    left: 111px !important;
   
}
.slick-next{
    left: -111px !important;

}
.slick-prev:before{
    position: absolute;
    content: '←' !important;
    background-color: #263644 !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    right: 50px !important;
    display: block !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: .25;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.slick-prev:before {
    content: '←';
    bottom: 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.slick-next:before {
    content: '←';
    bottom: 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.slick-next:before {
    content: '←';
    bottom: 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.slick-next:before {
    content: '→';
    position: absolute;
    left: 1289px !important;
    position: absolute;
    content: '←' !important;
    background-color: #263644 !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    right: 50px !important;
    display: block !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
a{
    color: black !important;
    text-decoration: none !important;
}
.border-solid{
    border: 1px solid black !important;
}
.bordreSolid{
    border: 1px solid #0000002b ;
}
.w-30{
    width: 30% !important;
}
.h-122{
    height: 122px;
}
.w-112{
    width: 112px !important;
}
.chekout{
    background-color: #00599e !important;
}
.h-140{
    height: 110px !important;
}
.linkprofile{
    color: #00599e !important;
    font-weight: bold;
   
}
.linkprofile:hover{
    text-decoration: underline !important;
}
/* .boxshow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} */
.box-shado{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.hoverNav:hover {
    background-color: #263644 !important;
    border-radius: 5px;
    color: white !important;
}
.hoverNav:hover .hoverNa{
    color: white;
}
/* .itemgover{
    background-color: red;
} */
.hoverNav{
    padding: 1px !important;
}
.dropdown-toggle::after{
    content: '' !important;
    display: none !important ;
    border-left: 0.3em solid transparent;
}
.mt-300{
    margin-top: 350px !important;
}
.mt-100{
    margin-top: 120px !important;
}

@media screen and (max-width: 1334px) {
  .slick-next{
    display: none !important;
}
  }
.d-r{
    direction: rtl;
  }

.w-120{
    width: 132px !important;
    height: 127px !important;
}
.font-12{
    font: 12px !important;
}
.h-img{
    height: 100px !important;
}
.font-9{
    font-size: 8px !important;
}
.h-70{
    height: 60vh;
}
.section::-webkit-scrollbar {
    width: 10px;
  }


  body::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: white;        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: black;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid black;  /* creates padding around scroll thumb */
  }
  .d-rtl{
    direction: rtl;
  }
  .font-15{
    font-size: 15px;
  }
  /* .img-detailes{
    height: 452px;
    border-radius: 19px;
  } */
.bg-orange{
    background-color: black;
}
.border50{
    border-radius: 50% ;
}
.sliederHover{
    position: absolute;
    background-color: #181b3c36;
    transition: 1.5s;
    top: 403px;
    left: 0;
    right: 0px;
    bottom: 0;
    width: 100%;
}
.post-slide:hover .sliederHover{
    transition: 1.5s;

    top: 0;
}
.btnhover{
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border: 1px solid #00599e !important;
}
.post-slide:hover .btnhover{
    background-color: #006bb4;
    color: white;
}

.hoverfooter:hover{
    color: #006bb4 !important;
    /* transition: 0.8s; */
    font-weight: bold;
}
.custom-select {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .custom-select:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .text-w{
    color: #8F9BB3 !important;
  }
  .arrow-up {
    position: absolute;
    top: -10px;
    left: 32%;
    transform: translateX(-50%);
    border-width: 5px 5px 0;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    z-index: 1;
  }
  
  .form-control{
    border-radius: 0 !important;
  }
  .hoverlenk{
    padding: 5px;
    transition: 0.5s;
  }
  .hoverlenk:hover{
    background: #00599e;
 
    /* padding: 5px; */
    border-radius: 50%;
    color: white !important;

}
.hoverlenk:hover .iconehover{
    color: white !important;
}
/* .spanicone{

    background-color: red;

}
.p-6{
    padding: 5px    !important;
} */

.fa-trash:hover{
    color: red !important;
    cursor: pointer;
}
.pointer-event{
    cursor: pointer;
}
.outline{
    outline: none !important;
}
.dir{
    direction: rtl;
}
.h-114{
    height: 58px !important ;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: black !important;
    appearance: none;
    background-color:#a0c3e54d !important;
    border: 1px solid #00599e !important;
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.bg-footer{
    background-color:#80808047 !important;
    height: 40px;
    border: none    ;

}
.pt-7{
    padding-top: 30px !important;
}
.h-144{
 height:  58px !important
}
.w-100{
    width: 100% !important;
}
@media only screen and (max-width: 600px) {
    
    .mt-300{
        margin-top: 334px !important;
    }
    .mt-6{
        margin-top: 200px !important;
    }
    .pt-7{
        padding-top: 0 !important;
        color: red !important;
    }
    
  }
  .search-header {
    direction: rtl; /* اتجاه من اليمين لليسار للنص باللغة العربية */
  }
  
  /* .search-header i {
    transform: scaleX(-1);
  }
  .inputposiion{
    position: absolute;
    width: 24%;
    top: 56px;
    z-index: 2222222222222222222222222222;

  } */